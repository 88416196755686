import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [

    {
        path: 'about',
        loadChildren: () => import('./components/about/about.module').then((m) => m.AboutModule)
    },
    {
        path: ':shareCode/webar/informationBanner',
        loadChildren: () => import('./components/information-banner/information-banner.module').then((m) => m.InformationBannerModule)
    },
    {
        path: ':shareCode/webar',
        loadChildren: () => import('./components/webar-loader/webar-loader.module').then((m) => m.WebarLoaderModule)
    },
    {
        path: ':shareCode/embed',
        loadChildren: () => import('./components/embed-page-redirect/embed-page-redirect.module').then((m) => m.EmbedPageRedirectModule)
    },
    {
        path: ':shareCode/view',
        loadChildren: () => import('./components/embed-page-redirect/embed-page-redirect.module').then((m) => m.EmbedPageRedirectModule)
    },
    {
        path: ':shareCode',
        loadChildren: () => import('./components/main/main.module').then((m) => m.MainModule)
    },
    {
        path: '',
        loadChildren: () => import('./components/share-code-form/share-code-form.module').then((m) => m.ShareCodeFormModule)
    },
    {
        path: '**',
        pathMatch: 'full',
        loadChildren: () => import('./components/error-page/error-page.module').then((m) => m.ErrorPageModule)
    }
];

// const routes: Routes = [
//   {
//     path: '',
//     pathMatch: 'full',
//     component: ShareCodeFormWrapperComponent,
//   },
//   {path: 'about', component: AboutComponent},
//   {path: ":shareCode", component: MainComponent},
//   {path: ":shareCode/embed", component: EmbedPageRedirectComponent},
//   {path: ":shareCode/view", component: EmbedPageRedirectComponent},
//   {path: ':shareCode/webar', component: WebarLoaderComponent},
//   {path: ':shareCode/webar/informationBanner', component: InformationBannerComponent},
//   {path: '**', pathMatch: 'full',component: ErrorPageComponent}
// ];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
