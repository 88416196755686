import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';

import { ErrorDialogComponent } from './errors/errors-dialog/errors-dialog.component';
import { ErrorDialogService } from './errors/errors-dialog/errors-dialog.service';
import { LoadingDialogService } from './loading/loading-dialog.service';
import { LoadingDialogComponent } from './loading/loading-dialog/loading-dialog.component';
import { PopupDialogComponent } from './popup/popup-dialog.component';
import { PopupDialogService } from './popup/popup-dialog.service';

@NgModule({
    declarations: [
        ErrorDialogComponent,
        LoadingDialogComponent,
        PopupDialogComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        MatButtonModule
    ],
    exports: [
        LoadingDialogComponent,
    ],
    providers: [
        ErrorDialogService,
        PopupDialogService,
        LoadingDialogService
    ]
})
export class SharedModule {
}
