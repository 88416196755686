import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { PopupDialogComponent } from './popup-dialog.component';

@Injectable()
export class PopupDialogService {
    private opened = false;

    constructor(private dialog: MatDialog) {
    }

    openDialog(message: string, title: string): void {
        if (!this.opened) {
            this.opened = true;
            const dialogRef = this.dialog.open(PopupDialogComponent, {
                data: { message, title },
                maxHeight: '100%',
                width: '600px',
                maxWidth: '100%',
                disableClose: true,
                hasBackdrop: true,
            });

            dialogRef.afterClosed().subscribe(() => {
                this.opened = false;
            });
        }
    }
}
