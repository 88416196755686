import { Component } from '@angular/core';
import * as WebFont from 'webfontloader';

import { environment } from '../environments/environment';
import Logger from './shared/logger';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    constructor() {
        WebFont.load({
            google: {
                families: [
                    'Material Icons',
                ],
            },
        });
        Logger.info(`Vuframe® SharePage v${environment.version}`);
    }
}
