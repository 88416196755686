import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'vf-error-dialog',
    templateUrl: './errors-dialog.component.html',
    styleUrls: ['./errors-dialog.component.scss'],
})
export class ErrorDialogComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: { message: string; status?: number }
    ) {}
}
